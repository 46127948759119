import { Button, ButtonGroup, IconButton, Panel, Placeholder, Stack, Timeline } from "rsuite";
import { addMessage, resetMessages, toggleDebugPanel } from "../features/previewerSlice";
import { map, reverse } from "lodash";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import { AiOutlineClose } from "react-icons/ai";

const subStyle = { fontSize: 10, color: "grey" }
const DebugPanel = () => {
    const timelineHandler = useRef(null);
    const dispatch = useDispatch();
    const [overflowPanel, setPanelFlow] = useState("hidden");
    const [panelMaxHeight, setPanelMaxHeight] = useState(250);
    const { showDebugPanel, messages } = useSelector((state) => state.previewer);
    const handleClose = () => {
        dispatch(toggleDebugPanel(false))
    }
    const handleMessage = (event) => {
     //   dispatch(addMessage({ message: event.data.type,type:event.data.type, ,timestamp: new Date().toLocaleTimeString(),  }));
        // console.warn("Message received: ", event.data.type)
        // console.log(event)
    }
    useEffect(() => {
        window.addEventListener("message", handleMessage);
        return () => {
            window.removeEventListener("message",handleMessage)
        }
    }, [])
    const isMobile = () => {
        return window.innerWidth < 768 ? true : false
    }
    const scrollTo = (to) => {
        if (timelineHandler.current) {
            timelineHandler.current.scrollTo(0, to);
        }
    };

    return <Panel
        style={ { userSelect: "none", overflow: "hidden", position: "fixed", display: showDebugPanel ? "block" : "none", height: panelMaxHeight, maxHeight:"50vh", top: isMobile()?0:10, left:isMobile()?0:10, maxWidth: "100%", width:"400px", backgroundColor: "white", zIndex: 90001 } }
        bordered
        shaded
        header={
            <Stack justifyContent="space-between">
                <span>Debug</span>
                <ButtonGroup >
                    <Button disabled={ messages.length < 4 ? true : false } onClick={ () => {
                        setPanelFlow(overflowPanel === "hidden" ? "auto" : "hidden");
                        setPanelMaxHeight(overflowPanel === "hidden" ? timelineHandler.current.scrollHeight : 250);
                        scrollTo(0);
                    } }>{ overflowPanel === "hidden" ? "Expand" : "Collapse" }
                    </Button>
                    <Button disabled={ messages.length < 1 } onClick={ () => {
                        dispatch(resetMessages())
                        setPanelFlow("hidden");
                        setPanelMaxHeight(250)
                    } }>Reset</Button>
                </ButtonGroup>
                <IconButton style={ { marginLeft: 20, backgroundColor:"transparent" } } circle icon={ <AiOutlineClose /> } onClick={ handleClose } />
            </Stack>
        }
    >
        { messages.length > 0 ? <Timeline ref={ timelineHandler } align={ "left" } style={ { overflow: overflowPanel, height: panelMaxHeight - 100,  maxHeight:"calc(50vh - 100px)", width: "100%" } } endless isItemActive={ index => index === 0 }>
            { map(reverse(messages.slice()), (message, index) => {
                return <Timeline.Item key={index}>{ message.message.charAt(0).toUpperCase() + message.message.substring(1, message.message.length) }<p style={ subStyle }>{ message.timestamp }</p></Timeline.Item>
            }) }
        </Timeline> : <span>No events detected</span> }

    </Panel>
}

export default DebugPanel