import 'openplayerjs/dist/openplayer.css';

import { ButtonToolbar, IconButton } from 'rsuite';
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import { AiFillAlipayCircle } from "react-icons/ai";
import OpenPlayerJS from 'openplayerjs';
import ReloadIcon from '@rsuite/icons/Reload';
import { setVastTag } from '../features/previewerSlice';

const VastPlayer = ({ vastURL, onReady }) => {
  const [playerObj, setPlayer] = useState(null);
  const dispatch = useDispatch();
  useEffect(() => {
    const player = new OpenPlayerJS("my-player", {
      controls: {
        alwaysVisible: false,
        layers: {
          left: ['play', 'time', 'volume'],
          middle: ['progress'],
          right: ['captions', 'settings', 'fullscreen'],
        }
      },
      detachMenus: false,
      forceNative: true,
      showLoaderOnInit: true,
      mode: 'responsive',
      ads: {
        src: vastURL,
        autoPlayAdBreaks: true,
        debug: false,
        enablePreloading: true,
        language: 'en',
        loop: false,
        numRedirects: 4,
        sdkPath: 'https://imasdk.googleapis.com/js/sdkloader/ima3.js',
        customClick: {
          enabled: false,
          label: '',
        },
        sessionId: null,
        vpaidMode: 'enabled',
        publisherId: null,
      },
      onError: (e) => console.error(e),
    });
    player.init();
    setPlayer(player);
  }, [vastURL]);

  return (
    <>
      <video id="my-player" className="op-player__media" autoPlay muted controls playsInline>
        <source src="https://vjs.zencdn.net/v/oceans.mp4" type="video/mp4" />
      </video>
      <ButtonToolbar>
        <IconButton icon={ <ReloadIcon /> } onClick={ () => {
          playerObj.destroy();
          dispatch(setVastTag("https://webopt.solution.weborama.fr/fcgi-bin/dispatch.fcgi?a.A=im&a.si=6&a.te=2420&a.he=1&a.wi=1&a.hr=X&a.ycp=&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_284}&a.ra=" + Math.random()));
        } }>Reload Pre-Roll</IconButton>
      </ButtonToolbar>
    </>
  );
}

export default VastPlayer;