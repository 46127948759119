import { AiOutlineClose, AiOutlineZoomIn, AiOutlineZoomOut } from "react-icons/ai";
import { Button, ButtonGroup, Dropdown, HStack, IconButton, Panel, Placeholder, Stack, Timeline } from "rsuite";
import { MOBILE_VIEWPORTS, searchParamsHandler } from "./../Constants"
import { get, map, reverse, unset } from "lodash";
import { resetMessages, setMobileDimensions, toggleMobileContainer } from "../features/previewerSlice";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";

import { useSearchParams } from "react-router-dom";

const frameStyle = { margin: 0, border: "none", background: "white" }
const mainStyle = { display: "flex", position: "fixed", top: 0, left: 0, width: "100vw", height: "100vh", overflow: "hidden", flexDirection: "column", backgroundColor: "black", justifyContent: "center", alignItems: "center" }
const MobileContainer = (props) => {
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams()
    const [zoomScale, setZoomScale] = useState(1);
    const { mobileDimensions } = useSelector((state) => state.previewer);
    const handleSelect = (eventKey) => {
        dispatch(setMobileDimensions(eventKey))
    }
    return <div style={ mainStyle }>
        <HStack style={ { top: 10, position: "absolute" } }>
            <ButtonGroup>
                <IconButton onClick={ () => {
                    setZoomScale(zoomScale + 0.1)
                } } icon={ <AiOutlineZoomIn /> } />
                <Button style={ { maxHeight: 34 } } onClick={ () => {
                    setZoomScale(1)
                } }>{ parseInt(zoomScale * 100) + "%" }</Button>
                <IconButton onClick={ () => {
                    setZoomScale(zoomScale - 0.1)
                } } icon={ <AiOutlineZoomOut /> } />
            </ButtonGroup>
            <Dropdown onSelect={ handleSelect } title={ get(mobileDimensions, "label") } menuStyle={ { minWidth: 200, marginTop: 5 } }>
                { map(MOBILE_VIEWPORTS, (devices, os) => {
                    return <Dropdown.Menu key={ os } title={ os }>
                        { map(devices, (value, index) => {
                            return <Dropdown.Item key={ get(value, "label") } eventKey={ { label: get(value, "label"), width: get(value, "width"), height: get(value, "height") } }>{ get(value, "label") }</Dropdown.Item>
                        }) }
                    </Dropdown.Menu>
                })
                }
            </Dropdown>
        </HStack>
        <iframe style={ {...frameStyle,  transform: "scale(" + zoomScale + ")",width: get(mobileDimensions, "width"), height: get(mobileDimensions, "height"),} }></iframe>
        <IconButton onClick={ () => {
            let __search = searchParamsHandler(searchParams);
            unset(__search, "mobi")
            setSearchParams(__search)
        } } appearance="primary" color="orange" style={ { bottom: 10, position: "absolute", color: "white", } } icon={ <AiOutlineClose /> } circle></IconButton>
    </div>
}
export default MobileContainer;