import { AiOutlineInfo, AiOutlineShareAlt } from "react-icons/ai";
import { Col, Container, Content, Grid, Header, IconButton, Nav, Navbar, Placeholder, Row, Toggle, Tooltip, VStack, Whisper, useToaster } from "rsuite";
import { FaCog, FaFilePdf } from "react-icons/fa";
import { addMessage, resetFormats, setFormatsData, setPreviewActive, toggleDebugPanel, toggleInformationDrawer, toggleMobileContainer, togglePDFModal, toggleSharingModal, toggleVPAIDPlayer } from "../features/previewerSlice";
import { get, isNil, set, unset } from "lodash";
import { memo, useEffect, useRef, useState } from "react";
import { message, renderTags, searchParamsHandler } from "../Constants";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";

import DebugPanel from "../components/DebugPanel";
import ExportModal from "../components/ExportModal";
import InfoDrawer from "../components/InfoDrawer";
import MobileContainer from "../components/MobileContainer";
import PDFModal from "../components/PDFModal"
import ShareModal from "../components/ShareModal";
import VastPlayer from '../components/VastPlayer';
import moment from "moment";

const styleRow = { border: "none" };
const Preview = () => {
  let captureRef = useRef(null);
  const [show_info, setInfo] = useState(false)

  const { plainCode, activeSlot, debug, formatInDisplay, showInformationDrawer, showSharingModal, showPDF,showDebugPanel, vastTag, showVPAIDPlayer, showMobileContainer,formatDimensions, activePreviewKey } = useSelector((state) => state.previewer);
  const { notificationPlacement } = useSelector((state) => state.app);
  const [screen_dimensions, setScreenDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const bannerStyle = { backgroundColor: "grey", margin: "0 auto", marginTop: 20, marginBottom: 20, overflow: "hidden" }
  const activeBannerStyle = { backgroundColor: "transparent", margin: "0 auto", marginTop: 20, marginBottom: 20, overflow: "hidden" }
  const dispatch = useDispatch();
  const toaster = useToaster();
  const handleResize = () => {
    let __dims = { width: window.innerWidth, height: window.innerHeight }
    if (window.innerWidth <= 575) {
      set(__dims, "breakpoint", "xs");
    }
    if (window.innerWidth > 575 && window.innerWidth <= 767) {
      set(__dims, "breakpoint", "sm");
    }
    if (window.innerWidth > 767 && window.innerWidth <= 991) {
      set(__dims, "breakpoint", "md");
    }
    if (window.innerWidth > 991 && window.innerWidth <= 1199) {
      set(__dims, "breakpoint", "lg");
    }
    if (window.innerWidth > 1199 && window.innerWidth <= 1399) {
      set(__dims, "breakpoint", "xl");
    }
    if (window.innerWidth > 1399) {
      set(__dims, "breakpoint", "xxl");
    }
    setScreenDimensions(__dims);
  }
  
  useEffect(() => {
    let fetchData = async () => {
      let formats = get(params, "ad_id", "").split(",");
      let output = await renderTags({ formats_ids: formats, rotation: formats.length > 1 ? true : false }, "html");
      if (get(output, "objects", []).length === 0) {
        toaster.push(message("Demo '" + get(params, "ad_id") + "' not found"), { placement: notificationPlacement, duration: 5000 });
      } else {
        dispatch(setFormatsData(output));
        }
    }
    fetchData();
  }, [params.ad_id]);
  useEffect(() => {
    handleResize()
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    }
  }, []);
  const handleMessage = (event) => {
    if (event.data.ad_id === params.ad_id) {
      let __message = { timestamp: moment().format("HH:mm:ss"), type: event.data.type, message: event.data.type.replaceAll("_", " ").toLowerCase() }
      dispatch(addMessage(__message));
    };
  }
  useEffect(() => {
    if (debug) {
      toaster.push(message("Debug Mode ON"), { placement: notificationPlacement, duration: 5000 });
    } else {
      toaster.push(message("Debug Mode OFF"), { placement: notificationPlacement, duration: 5000 });
    }
  }, [debug]);

  useEffect(() => {
    window.addEventListener('message', handleMessage);
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (searchParams.get("debug") === "true") {
      dispatch(toggleDebugPanel(true))
    }
    if (searchParams.get("mobi") === "true") {
      dispatch(toggleMobileContainer(true))
    } else {
      dispatch(toggleMobileContainer(false))
    }
  }, [searchParams.get("mobi"),searchParams.get("debug")])

  const onSelect = (eventKey) => {
    dispatch(setPreviewActive(eventKey))
  }

  return (
    <>
      <DebugPanel />
      { activeSlot === "layer" ? <iframe name="layer" id={ "1x1" } srcDoc={ plainCode } ref={ captureRef } style={ { ...activeBannerStyle, width: "100%", height: "100%", position: "fixed" } }></iframe> : null }
      <Container>
        <Header>
          <Navbar appearance="subtle">
            <Navbar.Brand href="/">Home</Navbar.Brand>
            <Nav onSelect={ onSelect } activeKey={ activePreviewKey }>
              <Nav.Item eventKey="home">Home</Nav.Item>
            </Nav>
            <Nav pullRight>
              <Nav.Item icon={ <FaCog /> }>Settings</Nav.Item>
            </Nav>
          </Navbar>
        </Header>
        <Content>
          <Grid fluid>
            <Col xsHidden smHidden mdHidden lgHidden xl={ 2 }></Col>
            <Col xs={ 24 } sm={ 24 } md={ 24 } lg={ 24 } xl={ 20 }>
              <Row style={ styleRow } >
                <Col style={ { "width": "100%", "flexDirection": "row", "justifyContent": "center", background: "black" } } xsHidden smHidden mdHidden  >
                  { activeSlot === "masthead" ? <iframe scrolling="no" name="masthead" id={ "masthead" } seamless srcDoc={ plainCode } ref={ captureRef } style={ { ...activeBannerStyle, width: "100%", height: "300px", border: "none", maxWidth: get(formatDimensions, "width"), maxHeight: get(formatDimensions, "height"), } }></iframe> : <div scrolling="no" name="masthead" id={ "masthead" } seamless ref={ captureRef } style={ { ...bannerStyle, width: "100%", height: "300px", border: "none" } }></div> }
                </Col>
              </Row>
              <Row style={ styleRow }>
                <Col style={ { background: "red", width: "100%", padding: 20 } }>
                  <Placeholder.Paragraph style={ { width: "100%" } } rows={ get(screen_dimensions, "width") < 767 ? 2 : 10 } rowHeight={ 16 } rowSpacing={ 12 } active={ true } graph="square" />
                </Col>
                <Col xsHidden smHidden lg={ 24 } style={ { background: "blue", width: "100%" } }>
                  { activeSlot === "leaderboard" ? <iframe name="leaderboard" id={ "728x90" } seamless srcDoc={ plainCode } ref={ captureRef } style={ { ...activeBannerStyle, width: 728, height: 90, border: "none" } }></iframe> : <div style={ { ...bannerStyle, width: 728, height: 90, border: "none" } }></div> }
                </Col>
                <Col xs={ 24 } sm={ 24 } md={ 14 } lg={ 16 } style={ { background: "pink", padding: 20 } }>
                  <Placeholder.Paragraph style={ { width: "100%", padding: 20 } } rows={ get(screen_dimensions, "width") < 767 ? 1 : 10 } rowHeight={ 16 } rowSpacing={ 12 } active={ true } />
                  {showVPAIDPlayer?<span>
                   <VastPlayer vastURL={vastTag} />
                  </span>:null}
                  <Placeholder.Paragraph style={ { width: "100%", padding: 20 } } rows={ get(screen_dimensions, "width") < 767 ? 1 : 10 } rowHeight={ 16 } rowSpacing={ 12 } active={ true } />
                  { activeSlot === "halfpage" ? <iframe name="halfpage" id={ "300x600" } seamless srcDoc={ plainCode } ref={ captureRef } style={ { ...activeBannerStyle, width: 300, height: 600, border: "none" } }></iframe> : <div style={ { ...bannerStyle, width: 300, height: 600, border: "none" } }></div> }
                  <Placeholder.Paragraph style={ { width: "100%" } } rows={ get(screen_dimensions, "width") < 767 ? 2 : 10 } rowHeight={ 16 } rowSpacing={ 12 } active={ true } />
                </Col>
                <Col xs={ 24 } mdHidden lgHidden xlHidden xxlHidden >
                  { activeSlot === "mobile_banner" ? <iframe name="mobile_banner" id={ "320x50" } seamless srcDoc={ plainCode } ref={ captureRef } style={ { ...activeBannerStyle, width: 320, height: 100, border: "none", marginBottom: 0, margin: 0 } }></iframe> : <div style={ { ...bannerStyle, width: 320, height: 100, maxWidth: get(formatDimensions, "width"), maxHeight: get(formatDimensions, "height"), border: "none", position: "fixed", bottom: 0, left: "calc(50% - 160px)", marginBottom: 0 } }>  </div>
                  }
                  <Placeholder.Paragraph style={ { width: "100%" } } rows={ 10 } rowHeight={ 16 } rowSpacing={ 12 } active={ true } />
                </Col>
                <Col xs={ 24 } sm={ 24 } md={ 10 } lg={ 8 } style={ { display: "flex", "flexDirection": "column", "justifyContent": "center", background: "green", padding: 20 } }>
                  { activeSlot === "mrec" ? <iframe name="mrec" id={ "333x280" } seamless srcDoc={ plainCode } ref={ captureRef } style={ { ...activeBannerStyle, width: 333, height: 280, border: "none", maxWidth: get(formatDimensions, "width") } }></iframe> : <div style={ { ...bannerStyle, width: 333, height: 280, border: "none" } }></div> }
                  <Placeholder.Paragraph style={ { width: "100%" } } rows={ 10 } rowHeight={ 16 } rowSpacing={ 12 } active={ true } />
                  { activeSlot === "wide_skyscrapper" ? <iframe name="wide_skyscrapper" id={ "160x600" } seamless srcDoc={ plainCode } ref={ captureRef } style={ { ...activeBannerStyle, width: 160, height: 600, border: "none", maxWidth: get(formatDimensions, "width") } }></iframe> : <div style={ { ...bannerStyle, width: 160, height: 600, border: "none" } }></div> }
                  <Placeholder.Paragraph style={ { width: "100%" } } rows={ 10 } rowHeight={ 16 } rowSpacing={ 12 } active={ true } />
                </Col>

              </Row>
            </Col>
          </Grid>
        </Content>
      </Container>

      <ExportModal />
      <ShareModal />
      <Whisper preventOverflow placement={ "top" } trigger={ ["hover"] } speaker={ <Tooltip>Debug</Tooltip> }>
        <Toggle checkedChildren="Debug On" unCheckedChildren="Debug Off" loading={ isNil(formatInDisplay) } style={ { position: "fixed", bottom: 10, left: 10, zIndex: 99999999999991 } } checked={ showDebugPanel } onChange={ () => {
          if (!showDebugPanel) {
            let __search = searchParamsHandler(searchParams);
            __search.debug = "true";
            setSearchParams(__search);
          } else {
            let __search = searchParamsHandler(searchParams);
            unset(__search, "debug");
            setSearchParams(__search);
          }
          dispatch(toggleDebugPanel(!showDebugPanel))
        } } size="lg" />

      </Whisper>
      <Whisper preventOverflow placement={ "left" } trigger={ ["hover"] } speaker={ <Tooltip>Information</Tooltip> }>
        <IconButton circle appearance="primary" color={ "blue" } icon={ <AiOutlineInfo /> } style={ { position: "fixed", bottom: 10, right: 90, zIndex: 3000000000003 } } onClick={ () => {
          dispatch(toggleInformationDrawer(!showInformationDrawer))
        } } />
      </Whisper>
      <Whisper preventOverflow placement={ "top" } trigger={ ["hover"] } speaker={ <Tooltip>PDF</Tooltip> }>
        <IconButton circle appearance="primary" color={ "red" } loading={isNil(formatInDisplay)} icon={ <FaFilePdf /> } style={ { position: "fixed", bottom: 10, right: 50, zIndex: 3000000000003 } } onClick={ () => {
          dispatch(togglePDFModal(!showPDF))
        } } />
      </Whisper>
      <Whisper preventOverflow placement={ "top" } trigger={ ["hover"] } speaker={ <Tooltip>Share</Tooltip> }>
        <IconButton circle appearance="primary" color={ "orange" } icon={ <AiOutlineShareAlt /> } style={ { position: "fixed", bottom: 10, right: 10, zIndex: 3000000000002 } } onClick={ () => {
          dispatch(toggleSharingModal(!showSharingModal))
        } } />
      </Whisper>
      <div style={ { color: "black", alignContents: "center", borderRadius: 5, opacity: show_info ? 1 : 0.4, position: "fixed", bottom:45, left: 10, zIndex: 3000000000002 } }>
        <VStack alignItems="flex-start" justifyContent="flex-start">
          <Toggle checkedChildren="Info" unCheckedChildren="Info" onChange={ () => {
            setInfo(!show_info)
          } } >
          </Toggle>
          <div style={{display:"flex", flexDirection:"row"}}>
          <span style={ { fontSize: 10, display: show_info ? "block" : "none" } }>{ get(screen_dimensions, "width") + "x" + get(screen_dimensions, "height") }</span><span style={ { fontSize: 10, display: show_info ? "block" : "none" } }>{ get(screen_dimensions, "breakpoint") }</span>
          </div>
        </VStack>
      </div>
      { showMobileContainer === true ? <MobileContainer /> : null }
      <InfoDrawer />
      <PDFModal/>
    </>
  );
};
export default memo(Preview);
