import { AD_SERVER_TECH, generateExcelTag, generateTextTags, renderTags } from "../Constants"
import { AiOutlineFileText, AiTwotoneFileExcel } from "react-icons/ai";
import { Button, Dropdown, Modal, Text } from "rsuite";
import { get, map } from "lodash";
import { setAdServer, toggleExportModal } from "../features/previewerSlice";
import { useDispatch, useSelector } from "react-redux";

import { useParams } from "react-router-dom";
import { useState } from "react";

const ExportModal = () => {
  const params = useParams();
  const [output, setOutput] = useState("");
  const dispatch = useDispatch();
  
  const { showExportModal, adServer, tagTexts,formatOutputs,formatObjects } = useSelector((state) => state.previewer);
  const handleClose = () => {
    dispatch(toggleExportModal(!showExportModal))
  }
  const displayTag = async (adserver="") => {
    let formats = get(params, "ad_id", "").split(",");
    let output = await renderTags({ formats_ids: formats, rotation: formats.length > 1 ? true : false }, "html", adserver);
    setOutput(output);

  }
  return <Modal keyboard={ false } onEnter={displayTag} open={ showExportModal } onClose={ handleClose }>
    <Modal.Header>
      <Modal.Title>Export Tag</Modal.Title>
    </Modal.Header>

    <Modal.Body>
      <code style={{ whiteSpace: 'pre-wrap' }}>{get(output, "plain_code","")}</code>
    </Modal.Body>
    <Modal.Footer>
      <Dropdown color={"blue"} appearance="primary" style={ { float: "left" } } onSelect={ async (value) => {
        dispatch(setAdServer(value))
        displayTag(value)
      } } title="Ad Server">
        {
          map(AD_SERVER_TECH, (tech, index) => {
            return <Dropdown.Item key={ index } eventKey={ tech.value }>{ tech.text }</Dropdown.Item>
          })
        }

      </Dropdown>
      <Button startIcon={ <AiTwotoneFileExcel /> } onClick={ ()=>{
          let formats = get(params, "ad_id", "").split(",");
          generateExcelTag(output,formats.length > 1?true:false, adServer) 
      } } appearance="primary" color="green"> 
        Excel
      </Button>
      <Button startIcon={ <AiOutlineFileText /> } onClick={ () => {
        let formats = get(params, "ad_id", "").split(",");
        generateTextTags(output,formats.length > 1?true:false, adServer) 
      } } appearance="primary" color="orange">
        Text
      </Button>
    </Modal.Footer>
  </Modal>

}

export default ExportModal