import { Button, Dropdown, Loader, Modal, Placeholder } from "rsuite";
import { Document, Font, Image, Link, PDFDownloadLink, Page, StyleSheet, Text, View } from '@react-pdf/renderer';
import React, { useEffect, useRef, useState } from "react";
import { each, get, isString, map, set } from "lodash";
import { useDispatch, useSelector } from "react-redux";

import axios from "axios";
import { format } from "date-fns";
import { togglePDFModal } from "../features/previewerSlice";
import { useParams } from "react-router-dom";

const PDFModal = (props) => {
    const dispatch = useDispatch();
    const [selectedElement, setSelectedElement] = useState("Select Element");
    const [pdfStyle, setPDFStyle] = useState({});
    const [elements, setElements] = useState([]);
    const [dpi, setDpis] = useState(72);
    const [loading, setLoading] = useState(false);
    const [elementChilds, setElementsChilds] = useState([]);
    const [landingPage, setLandingPage] = useState(null)

    const { showPDF, formatInDisplay, plainCode, formatDimensions } = useSelector((state) => state.previewer);
    let params = useParams();
    let iframeRef = useRef(null);
    let dpiRef = useRef(null);
    const setup = async () => {
        setLoading(true)
        console.log(params, formatInDisplay)
        let response = await axios.get("https://cdn.voxelscope.com/" + get(formatInDisplay, "account_id") + "/formats/" + get(params, "ad_id") + ".json");
        let _dropElements = map(get(response, "data.rules.elements", []), (element) => {
            return {
                label: element.attributes.id, value: element.attributes.id, style:element.style
            }
        })
        console.log(get(response, "data", []))
        let __elementChilds = {}
        each(get(response, "data.rules.elements", []), (element) => {
            set(__elementChilds, element.attributes.id, get(element, "childs", []))
        })
        setSelectedElement(_dropElements[0].value)
        setElements(_dropElements)
        setElementsChilds(__elementChilds)
        setLandingPage(get(response, "data.rules.landing_page"))
        setLoading(false)
        let styles = StyleSheet.create({
            page: {
                flexDirection: 'row',
                backgroundColor: get(_dropElements[0].style, "backgroundColor", "#FFFFFF"),
                width: get(formatDimensions, "width"),
                height: get(formatDimensions, "height")
            },
            section: {
                margin: 0,
                padding: 0,
                flexGrow: 1,
            },
        });
        console.log(styles)
        setPDFStyle(styles)
    }
    const convertCalcToAbsolute = (key, calc) => {
        const regex_px2px = /calc\((\d+(\.\d+)?)px\s*\+\s*(\d+(\.\d+)?)px\)/;
        const regex_per2per = /calc\((\d+)%\s*\+\s*(\d+)%\)/;
        const regex_per2px = /calc\((\d+)%\s*\+\s*(\d+(\.\d+)?)px\)/;
        const matches_px2px = calc.match(regex_px2px);
        const matches_per2per = calc.match(regex_per2per);
        const matches_per2px = calc.match(regex_per2px);

        console.log(key, calc, matches_px2px, matches_per2per)

        let absolutePosition = 0;
        let value1 = 0;
        let value2 = 0;
        if (matches_px2px) {
            value1 = parseFloat(matches_px2px[1]);
            value2 = parseFloat(matches_px2px[3]);
            absolutePosition = value1 - value2;
        } else if (matches_per2per) {
            const percentage1 = parseFloat(matches_per2per[1]);
            const percentage2 = parseFloat(matches_per2per[2]);
            if (key === "width" || key === "left" || key === "right") {
                value1 = (percentage1 / 100) * get(formatDimensions, "width");
                value2 = (percentage2 / 100) * get(formatDimensions, "width");
            }
            if (key === "height" || key === "top" || key === "bottom") {
                value1 = (percentage1 / 100) * get(formatDimensions, "height");
                value2 = (percentage2 / 100) * get(formatDimensions, "height");
            }
            absolutePosition = value1 - value2;
        } else if (matches_per2px) {
            const percentage = parseFloat(matches_per2px[1]);
            const pixels = parseFloat(matches_per2px[3]);
            if (key === "width" || key === "left" || key === "right") {
                value1 = (percentage / 100) * get(formatDimensions, "width");
                value2 = pixels;
            }
            if (key === "height" || key === "top" || key === "bottom") {
                value1 = (percentage / 100) * get(formatDimensions, "height");
                value2 = pixels;
            }
            absolutePosition = value1 + value2;
        }
        return absolutePosition;
    }
    const PDFFormat = (pdf_props) => {
        let { formatData } = pdf_props;
        console.log(formatData)
        return <Document subject={"AdVoxel banner"} creator={get(formatInDisplay,"account_id")} producer={"AdVoxel Studio"} author={get(formatInDisplay,"author")} title={get(formatInDisplay,"format_name")}>
            <Page size={ [get(formatDimensions, "width"), get(formatDimensions, "height")] } dpi={ 72 } style={ pdfStyle }>
                {
                    map(formatData, (element_child) => {
                        each(element_child.style, (value, key) => {
                            if (isString(value)) {
                                if (value.indexOf("calc(") > -1) {
                                    element_child.style[key] = convertCalcToAbsolute(key, value) + "px"
                                }
                            }
                        })
                        console.log(element_child.layer_index)
                        switch(element_child.childType) {
                            case "button":
                                element_child.style.backgroundColor = "transparent";
                                    return <Link style={ { ...element_child.style,zIndex:element_child.layer_index } } src={landingPage} key={ element_child.uuid }></Link>
                            case "image":
                                    return <View  key={ element_child.uuid } style={{...element_child.style, zIndex:element_child.layer_index}}>
                                        <Image src={ element_child.style.backgroundImage.replace("url(","").replace(")","")}style={{ objectFit:element_child.style.backgroundSize } }  ></Image>
                                    </View>
                                default:
                                    return <View style={ { ...element_child.style ,zIndex:element_child.layer_index} } key={ element_child.uuid }></View>
                        }
                        
                    })
                }
            </Page>
        </Document>
    }

    const handleClose = () => {
        dispatch(togglePDFModal(false));
    };

    useEffect(() => {
        if(dpiRef.current){
            setDpis(dpiRef.current.offsetWidth)
        }
    }, [dpiRef])
    return (
        <Modal
            open={ showPDF }
            size={ "full" }
            onClose={ handleClose }
            onEntered={ setup }
            onExited={ () => {
            } }
        >
            <Modal.Header>
                <Modal.Title>
                </Modal.Title>
                <Dropdown title={ selectedElement } size={ "md" } appearance="default">
                    { map(elements, (element) => {
                        return <Dropdown.Item onSelect={ (data, event) => {
                            setSelectedElement(data)
                        } } key={ element.value }>  { element.label }</Dropdown.Item>
                    }) }
                </Dropdown>
            </Modal.Header>
            <Modal.Body style={ { display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" } }>
                <div ref={dpiRef} style={{ display:"none",position:"absolute",top:10,height:"1in", width:"1in",position:"absolute", backgroundColor:"grey"}}>{"DPIs:" + dpi}</div>
                { loading ? <Loader size="md" /> : <iframe ref={ iframeRef } height={ get(formatDimensions, "height") } width={ get(formatDimensions, "width") } srcDoc={ plainCode }></iframe> }
            </Modal.Body>
            <Modal.Footer style={ { textAlign: "center" } } >
                <PDFDownloadLink document={ <PDFFormat formatData={ get(elementChilds, selectedElement, []) } /> } fileName={ get(formatInDisplay,"format_name") + "_v" + get(formatInDisplay,"version") + ".pdf" }> <Button appearance="primary">
                    Print
                </Button></PDFDownloadLink>
            </Modal.Footer>
        </Modal>
    );
}
export default PDFModal;
