import { Button, Input, Modal, useToaster } from "rsuite";
import { useDispatch, useSelector } from "react-redux";
import { useRef, useState } from "react";

import QRCode from "qrcode";
import { message } from "../Constants";
import { toggleSharingModal } from "../features/previewerSlice";

const QRCodeModal = () => {
    const dispatch = useDispatch();
    const qrcodeRef = useRef();
    const [QRData, setQRData] = useState("");
    const { showSharingModal, } = useSelector((state) => state.previewer);
    const { notificationPlacement, } = useSelector((state) => state.app);
    const handleClose = () => {
        dispatch(toggleSharingModal(!showSharingModal))
    }
    const toaster = useToaster();
    const copyImageToClipboard = async () => {
        try {
          const imageElement = qrcodeRef.current;
          if (!imageElement) return;
          const canvas = document.createElement('canvas');
          canvas.width = imageElement.width;
          canvas.height = imageElement.height;
          const context = canvas.getContext('2d');
          context.drawImage(imageElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(async (blob) => {
            if (blob) {
              await navigator.clipboard.write([
                new ClipboardItem({
                  'image/png': blob
                })
              ]);
              toaster.push(message("Image copied"), { placement: notificationPlacement, duration: 5000 });
            }
          }, 'image/png');
        } catch (error) {
          console.error("Failed to copy image: ", error);
          toaster.push(message("Fail to copy to clipboard"), { placement: notificationPlacement, duration: 5000 });
        }
      };
      const copyURLToClipboard = async (text) => {
        try {
          await navigator.clipboard.writeText(text);
          toaster.push(message("URL copied"), { placement: notificationPlacement, duration: 5000 });
        } catch (error) {
          console.error("Failed to copy text: ", error);
          toaster.push(message("Fail to copy to clipboard"), { placement: notificationPlacement, duration: 5000 });
        }
      };
    const generateQRCode =  () => {
        QRCode.toDataURL(window.location.href, {
            errorCorrectionLevel: 'H',
            quality: 1,
            margin: 1
          }).then(url => {
            setQRData(url)
          }).catch(err => {
            console.error(err)
          });

    }
    return <Modal style={{zIndex:90003}} backdrop={true} onEnter={generateQRCode} keyboard={ true } open={ showSharingModal } onClose={ handleClose }>
        <Modal.Header>
            <Modal.Title>Share</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{display:"flex", flexDirection:"column", alignItems:"center", justifyItems:"center", }}>
           
            <Input readOnly value={ window.location.href } style={{marginBottom:20}}/>
            <img ref={qrcodeRef} src={QRData} style={{width:250, marginBottom:0}}/>
        </Modal.Body>
        <Modal.Footer>
            <Button onClick={ copyURLToClipboard} color={"orange"} appearance="primary">
                Copy URL
            </Button>
            <Button onClick={ copyImageToClipboard} color={"blue"} appearance="primary">
                Copy QRCode
            </Button>
        </Modal.Footer>
    </Modal>

}

export default QRCodeModal