import "./App.css";
import 'rsuite/dist/rsuite.min.css';

import { Route, Routes } from "react-router-dom";

import Home from "./pages/Home";
import NoMatch from "./pages/NoMatch";
import Preview from "./pages/Preview";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route index element={<Home />} />
        <Route path="/:ad_id" element={<Preview />} />
        <Route path="*" element={<NoMatch />} />
      </Routes>
    </div>
  );
}

export default  App;
