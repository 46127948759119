import { AD_SERVER_TECH, generateExcelTag, generateTextTags, renderTags } from "../Constants"
import { Accordion, Button, Container, Divider, Drawer, Dropdown, Heading, IconButton, List, FlexboxGrid, useToaster } from "rsuite";
import { AiOutlineCloudServer, AiOutlineFileExcel, AiOutlineFileText, AiTwotoneCopy } from "react-icons/ai";
import { HStack, VStack } from 'rsuite';
import { concat, find, get, last, map, set } from "lodash";
import { setAdServer, toggleExportModal } from "../features/previewerSlice";
import axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import {
    BlobWriter,
    HttpReader,
    ZipWriter,
} from "@zip.js/zip.js";
import { saveAs } from "file-saver";
import { message } from "../Constants";
import { toggleInformationDrawer } from "../features/previewerSlice"
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import moment from "moment";
import {byteToMB} from "./../Constants"
const regularStyle = { display: "inline-block", fontWeight: "normal", fontSize:11 }
const boldStyle = { width: 100, display: "inline-block", fontWeight: "bold" }
const descriptionStyle = { marginLeft: 10 }
const InfoDrawer = () => {
    const params = useParams();
    const [assetsInfo, setAssetsInfo] = useState([]);
    const [output, setOutput] = useState("");
    const dispatch = useDispatch();
    const toaster = useToaster();
    const { notificationPlacement } = useSelector((state) => state.app);
    const { adServer, showInformationDrawer,activeSlot, formatInDisplay } = useSelector((state) => state.previewer);

    useEffect(() => {
        if (showInformationDrawer) {
            getFilesInfo()
        }
    },[showInformationDrawer])

    const copyTagToClipBoard = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            toaster.push(message("Tag copied"), { placement: notificationPlacement, duration: 5000 });
        } catch (error) {
            console.error("Failed to copy text: ", error);
            toaster.push(message("Fail to copy to clipboard"), { placement: notificationPlacement, duration: 5000 });
        }
    };
    const generateZip = async () => {
        const zipWriter = new ZipWriter(new BlobWriter("application/zip"));
        let files_array = []
        files_array = map(get(formatInDisplay, "specs.files", []), (file, index) => {
            return zipWriter.add(last(file.split("/")), new HttpReader(file));
        })
        await Promise.all(files_array);
        return zipWriter.close();
    }
    const downloadAssets = async () => {
       let zip_blob = await generateZip()
       saveAs(zip_blob, get(formatInDisplay,"ad_id", "assets") + ".zip");
    }
    const getFilesInfo = async () => {
        let promises = map(concat(get(formatInDisplay, "specs.files", []),get(formatInDisplay, "specs.external_scripts", [])), (url, index) => {
            return new Promise(async(resolve)=>{
                try {
                    const response = await axios.head(url);
                    const fileSize = byteToMB(response.headers['content-length']);
                    const contentType = response.headers['content-type'];
                    const lastModified = response.headers['last-modified'];
                    resolve({mime: contentType, url: url, size:fileSize,updated:moment(new Date(lastModified)).format("YYYY-MM-DD HH:mm:ss"), filename: last(url.split("/"))})
                } catch (error) {
                    console.error('Error fetching MIME type:', error);
                    resolve({mime: "unknown", url: url, size:0,updated:"", filename: last(url.split("/"))})
                }
            })
        })

        Promise.all(promises).then((values) => {
            setAssetsInfo(values);
            console.log(values)
            return values;
        })
       
    }
    const displayTag = async (adserver = "") => {
        let formats = get(params, "ad_id", "").split(",");
        let output = await renderTags({ formats_ids: formats, rotation: formats.length > 1 ? true : false }, "html", adserver);
        setOutput(output);
    }
    return <Drawer style={ {
        maxWidth: "100%", zIndex: 90002
    } } open={ showInformationDrawer } className="responsive-drawer" onEnter={ displayTag } onClose={ () => {
        dispatch(toggleInformationDrawer(false))
    } }>
        <Drawer.Header>
            <Drawer.Title>Format Information</Drawer.Title>
            <Drawer.Actions>
                {/* <Button onClick={ () => dispatch(toggleInformationDrawer(false)) } appearance="primary">
                    Close
                </Button> */}
            </Drawer.Actions>
        </Drawer.Header>
        <Drawer.Body style={ { padding: 10 } }>
            <Accordion>
                <Accordion.Panel header="Metadata" defaultExpanded>
                    <div><span style={ boldStyle }>{ "Account" }</span><span style={ descriptionStyle }>{ get(formatInDisplay, "account_label") }</span></div>
                    <div><span style={ boldStyle }>{ "Account ID" }</span><span style={ descriptionStyle }>{ get(formatInDisplay, "account_id") }</span></div>
                    <div><span style={ boldStyle }>{ "Format Name" }</span><span style={ descriptionStyle }>{ get(formatInDisplay, "format_name") }</span></div>
                    <div><span style={ boldStyle }>{ "Dimensions" }</span><span style={ descriptionStyle }>{ get(formatInDisplay, "slot_dimensions") } <b> {" ["}{activeSlot}{"]"}</b></span></div>
                    <div><span style={ boldStyle }>{ "Filesize KBs" }</span><span style={ descriptionStyle }>{ get(formatInDisplay, "size_kbs") }</span></div>
                    <div><span style={ boldStyle }>{ "Version" }</span><span style={ descriptionStyle }>{ get(formatInDisplay, "version") }</span></div>
                </Accordion.Panel>
                <Accordion.Panel header="Trackers">
                    <div><span style={ boldStyle }>{ "Landing Page" }</span><span style={ descriptionStyle }>{ get(formatInDisplay, "specs.landing_page") }</span></div>
                    { map(get(formatInDisplay, "specs.trackers", []), (tracker) => {
                        let type = tracker.type.replaceAll("_", " ")
                        return <div><span style={ boldStyle }>{ type.charAt(0).toUpperCase() + type.slice(1) }</span><span style={ descriptionStyle }>{ tracker.url }</span></div>
                    }) }
                </Accordion.Panel>
                <Accordion.Panel header="Assets">
                       
                        <List>
                            <List.Item>
                                <FlexboxGrid>
                                    <FlexboxGrid.Item colspan={ 12 }><span style={{ ...boldStyle, fontSize:12} }>Filename</span></FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={ 4 }><span style={{ ...boldStyle, fontSize:12} }>Type</span></FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={ 2 }><span style={{ ...boldStyle, fontSize:12} }>Size</span></FlexboxGrid.Item>
                                    <FlexboxGrid.Item colspan={ 6 }><span style={{ ...boldStyle, fontSize:12} }>Updated</span></FlexboxGrid.Item>
                                </FlexboxGrid>
                            </List.Item>
                             {map(assetsInfo, (asset, index) => {
                            return <List.Item key={"asset_" + index}>
                            <FlexboxGrid>
                                <FlexboxGrid.Item colspan={ 12 }><span style={ regularStyle }>{get(asset, "filename")}</span></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={ 4 }><span style={ regularStyle }>{get(asset, "mime")}</span></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={ 2 }><span style={ regularStyle }>{get(asset, "size")}</span></FlexboxGrid.Item>
                                <FlexboxGrid.Item colspan={ 6 }><span style={ regularStyle }>{get(asset, "updated")}</span></FlexboxGrid.Item>
                            </FlexboxGrid>
                        </List.Item>
                        })} 
                        </List>
                       
                        <Divider />
                        <span style={ boldStyle }>{ "Files" }</span><span style={ descriptionStyle }>{ get(formatInDisplay, "specs.files", []).length }</span>
                    <div><span style={ boldStyle }>{ "Scripts" }</span><span style={ descriptionStyle }>{ get(formatInDisplay, "specs.external_scripts", []).length }</span></div>
                    <Button style={ { width: "100%" } } startIcon={ <AiOutlineFileText /> } onClick={ () => {
                       downloadAssets()
                    } } appearance="primary" color="orange">
                        Download Assets
                    </Button>
                </Accordion.Panel>
                <Accordion.Panel header="Events">
                    <div><span style={ boldStyle }>{ "View Time" }</span><span style={ descriptionStyle }>{ get(formatInDisplay, "specs.custom_events.view_timeout", 0) + "s" }</span></div>
                    <div><span style={ boldStyle }>{ "View Area" }</span><span style={ descriptionStyle }>{ get(formatInDisplay, "specs.custom_events.view_threshold", 0) * 100 + "%" }</span></div>
                </Accordion.Panel>
                <Accordion.Panel header="Tag">
                <Container style={ { marginBottom: 20, backgroundColor: "black", borderRadius: 5, padding: 5 } }>
                    <IconButton style={ { position: "absolute", right: 26 } } icon={ <AiTwotoneCopy /> } onClick={ () => {
                        copyTagToClipBoard(get(output, "plain_code", ""))
                    } } />
                    <code style={ { whiteSpace: 'pre-wrap', color: "white", fontSize: 12, padding: 20 } }>{ get(output, "plain_code", "") }
                    </code>
                </Container>
                <Divider />
                <VStack>
                    <Dropdown style={ { width: "100%", display: "grid" } } startIcon={ <AiOutlineCloudServer /> } color={ "blue" } appearance="primary" onSelect={ async (value) => {
                        dispatch(setAdServer(value));
                        displayTag(value);
                    } } title={ adServer === "" ? "Select Ad Server" : "Ad Server " + get(find(AD_SERVER_TECH, { value: adServer }), "text") }>
                        {
                            map(AD_SERVER_TECH, (tech, index) => {
                                return <Dropdown.Item key={ index } eventKey={ tech.value }>{ tech.text }</Dropdown.Item>
                            })
                        }
                    </Dropdown>
                    <Button style={ { width: "100%" } } startIcon={ <AiOutlineFileExcel /> } onClick={ () => {
                        let formats = get(params, "ad_id", "").split(",");
                        generateExcelTag(output, formats.length > 1 ? true : false, adServer)
                    } } appearance="primary" color="green">
                        Export Excel
                    </Button>
                    <Button style={ { width: "100%" } } startIcon={ <AiOutlineFileText /> } onClick={ () => {
                        let formats = get(params, "ad_id", "").split(",");
                        generateTextTags(output, formats.length > 1 ? true : false, adServer)
                    } } appearance="primary" color="orange">
                        Export Text
                    </Button>
                </VStack>
            </Accordion.Panel>
            </Accordion>
            <Divider />
        </Drawer.Body>
    </Drawer>
}

export default InfoDrawer