import { each, get, shuffle } from 'lodash'

import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  activePreviewKey: null,
  formatObjects: [],
  formatOutputs: [],
  messages : [],
  formatInDisplay: null,
  tagTexts: null,
  plainCode: null,
  adServer: null,
  showInformationDrawer: false,
  activeSlot:null,
  formatDimensions:null,
  showSharingModal:false,
  feedId: null,
  vastTag: null,
  showControlPanel:false,
  mobileDimensions:{ label: "iPhone 15 Pro", width: 393, height: 852 },
  showExportModal:false,
  showTrackingPanel:false,
  showDebugPanel:false,
  showMobileContainer:false,
  showVPAIDPlayer:false,
  showPDF:false
}

const solveActiveSlot = (format_dimensions, format_type) => {
  console.log(format_type)
  if(get(format_dimensions,"width",0) <= 100 && get(format_dimensions,"height",0)<=100){
    if(format_type === "vast") {
      return "vast"
    }
    if(format_type === "layer" || format_type === "interstitial" || format_type === "intro"){ 
      return "layer"
    }
  } else if(get(format_dimensions,"width",0) <= 320 && get(format_dimensions,"height",0)<=100){
    return "mobile_banner"
  } else if(get(format_dimensions,"width",0) <= 336 && get(format_dimensions,"height",0)<=280){
    return "mrec"
  } else if(get(format_dimensions,"width",0) <= 160 && get(format_dimensions,"height",0)<=280){
    return "wide_skyscrapper"
  }else if(get(format_dimensions,"width",0) <= 300 && get(format_dimensions,"height",0)<=600){
    return "halfpage"
  }else if(get(format_dimensions,"width",0) <= 728 && get(format_dimensions,"height",0)<=90){
    return "leaderboard"
  }  else if(get(format_dimensions,"width",0) <= 2000 && get(format_dimensions,"height",0)<=400){
    return "masthead"
  }
}

export const previewerSlice = createSlice({
  name: 'previewer',
  initialState,
  reducers: {
    resetFormats: (state) => {
      state.vastTag =  "https://webopt.solution.weborama.fr/fcgi-bin/dispatch.fcgi?a.A=im&a.si=6&a.te=2420&a.he=1&a.wi=1&a.hr=X&a.ycp=&gdpr=${GDPR}&gdpr_consent=${GDPR_CONSENT_284}&a.ra=";
      state.formatObjects =  [];
      state.formatOutputs = [];
      state.formatInDisplay = null;
      state.mobileDimensions = { label: "iPhone 15 Pro", width: 393, height: 852 };
      state.showControlPanel = false;
      state.formatDimensions = null;
      state.plainCode =null
      state.messages = []
      state.tagTexts = null
      state.adServer = null
      state.activeSlot=null
      state.feedId=null
      state.retargeting=null
    },
    toggleInformationDrawer:(state, action)=>{
      state.showInformationDrawer = action.payload;
    },
    addMessage: (state,action) => {
      state.messages.push(action.payload)
    },
    resetMessages: (state) => {
      state.messages = []
    },
    toggleSharingModal: (state,action) => {
      state.showSharingModal = action.payload
    },
    toggleTrackersPanel:(state, action)=>{
      state.showTrackingPanel = action.payload;
    },
    setPreviewActive:(state, action)=>{
      state.activePreviewKey = action.activeKey;
    },
    setFormatsData: (state,action) => {
      console.log(action.payload)
      state.formatObjects = get(action,"payload.objects", []);
      if(get(action,"payload.objects", []).length >0){
        if( get(action,"payload.objects[0]",  []).length > 1) {
          state.formatInDisplay = shuffle(get(action,"payload.objects", null));
          each(get(action,"payload.outputs", null), (text) => {
            if(text.indexOf(get(state.formatInDisplay,"ad_id", null)) > -1){
              state.plainCode = text;
            }
          })
        } else {
          state.formatInDisplay = get(action,"payload.objects[0]", null);
          state.plainCode = get(action,"payload.outputs[0]", null);
        }
        
        state.formatOutputs = get(action,"payload.outputs", []);
        state.tagTexts = get(action,"payload.texts",null);
        state.adServer = get( state.formatInDisplay ,"ad_server","");
        let format_dimensions = {width: parseInt(get( state.formatInDisplay ,"slot_dimensions","1x1").split("x")[0]), height: parseInt(get( state.formatInDisplay ,"slot_dimensions","1x1").split("x")[1])}

        state.activeSlot = solveActiveSlot(format_dimensions, get(state.formatInDisplay,"type"))
        state.formatDimensions = format_dimensions;
        state.feedId = get( state.formatInDisplay ,"feed_id",null);
      } else {
        state.formatInDisplay = null;
        state.formatOutputs = [];
        state.plainCode =null
        state.feedId =null
        state.tagTexts = null
        state.activeSlot=null
      }
    },
    toggleVPAIDPlayer:(state, action)=>{
      state.showVPAIDPlayer = action.payload;
    },
    toggleMobileContainer:(state, action)=>{
      state.showMobileContainer = action.payload;
    },
    toggleExportModal:(state, action)=>{
      state.showExportModal = action.payload;
    },
    setAdServer: (state,action) => {
      state.adServer = action.payload
    },
    setRetarget: (state,action) => {
      state.retargeting = action.payload
    },
    setFeedId: (state,action) => {
      state.feedId = action.payload
    },
    setMobileDimensions: (state,action) => {
      state.mobileDimensions = action.payload
    },
    setVastTag: (state,action) => {
      state.vastTag = action.payload
    },
    toggleDebugPanel: (state,action) => {
      state.showDebugPanel = action.payload
    },
  
   togglePDFModal: (state,action) => {
      state.showPDF = action.payload
    },
  
  },
})

// Action creators are generated for each case reducer function
export const {togglePDFModal, resetFormats,setFeedId,toggleExportModal,toggleSharingModal,toggleDebugPanel,setRetarget, addMessage, setFormatsData, resetMessages,toggleInformationDrawer, toggleMobileContainer,toggleVPAIDPlayer,setMobileDimensions,setVastTag,setAdServer,toggleTrackersPanel,setPreviewActive} = previewerSlice.actions
export default previewerSlice.reducer