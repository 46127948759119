import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  notificationPlacement: "bottomCenter",
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setNotificationPlacement: (state,action) => {
      state.notificationPlacement = action.payload
    },
  },
})

// Action creators are generated for each case reducer function
export const { setNotificationPlacement} = appSlice.actions
export default appSlice.reducer